// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mr-skanning-index-js": () => import("./../../../src/pages/mr-skanning/index.js" /* webpackChunkName: "component---src-pages-mr-skanning-index-js" */),
  "component---src-pages-nukleaermedisin-index-js": () => import("./../../../src/pages/nukleaermedisin/index.js" /* webpackChunkName: "component---src-pages-nukleaermedisin-index-js" */),
  "component---src-pages-roentgen-index-js": () => import("./../../../src/pages/roentgen/index.js" /* webpackChunkName: "component---src-pages-roentgen-index-js" */),
  "component---src-pages-simulatorer-index-js": () => import("./../../../src/pages/simulatorer/index.js" /* webpackChunkName: "component---src-pages-simulatorer-index-js" */),
  "component---src-pages-straaleterapi-index-js": () => import("./../../../src/pages/straaleterapi/index.js" /* webpackChunkName: "component---src-pages-straaleterapi-index-js" */),
  "component---src-pages-ultralyd-index-js": () => import("./../../../src/pages/ultralyd/index.js" /* webpackChunkName: "component---src-pages-ultralyd-index-js" */)
}

